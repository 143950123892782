import {
  Competiton,
  SpeedCompetitionCategoryResult,
} from '../models/Competition';

/**
 * A list of competitions
 */
interface CompetitionList {
  competitions: Competiton[];
}

/**
 * A class for dealing with the digitalrock api
 */
export class DigitalrockAPi {
  private BASE_URL = 'https://www.digitalrock.de/egroupware/ranking/json.php?';
  // private BASE_URL = '/test.json?';

  /**
   * function to get competitions
   * @param {string} nation the nation to get
   * @return {Promis<CompetitionList>} competition list
   */
  public async getCompetitions(nation: string): Promise<CompetitionList> {
    return await this._request([['nation', nation]])
      .then(r => r.json())
      .then(j => j as CompetitionList);
  }

  /**
   *
   * @param {string} competitionId the id of the competition
   * @param {string} categoryId the id of the category
   * @return {Promise<SpeedCompetitionCategoryResult>}
   */
  public async getCompetitionResults(
    competitionId: string,
    categoryId: string,
  ): Promise<SpeedCompetitionCategoryResult> {
    return await this._request([
      ['comp', competitionId],
      ['cat', categoryId],
    ])
      .then(r => r.json())
      .then(j => j as SpeedCompetitionCategoryResult);
  }

  /**
   *
   * @param {[string][]} params GET query parameters
   * @return {Promise<Response>} the response
   */
  private async _request(params: string[][]) {
    return await fetch(this.BASE_URL + new URLSearchParams(params).toString());
  }
}

import { Breakpoint } from '@mui/material/styles';
import { createContext } from 'react';
import { DigitalrockAPi } from './DigitalrockApi';

/**
 * Creates a Context that is accessible to all nodes wrapped in the context provider
 * @return {React.Context}
 */
export const Context = createContext<{
  api: DigitalrockAPi;

  title: string;
  setTitle: (title: string) => void;

  containerMaxWidth: false | Breakpoint | undefined;
  setContainerMaxWidth: (maxWidth: false | Breakpoint | undefined) => void;
}>({
  api: new DigitalrockAPi(),

  title: '',
  setTitle: () => {
    return;
  },

  containerMaxWidth: 'lg',
  setContainerMaxWidth: () => {
    return;
  },
});

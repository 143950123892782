import Container from '@mui/material/Container';
import React from 'react';
import { Context } from '../data/Context';
import LocalizationProviderWrapper from './LocalizationProviderWrapper';

/**
 * Wrapps the page with all necessary utils
 * @param  {{ children: JSX.Element }} props accepts JSX elements to wrap theme in
 * @return {JSX.Element}
 */
export default function PageTemplate(props: { children: JSX.Element }) {
  const { children } = props;
  const { containerMaxWidth } = React.useContext(Context);

  return (
    <LocalizationProviderWrapper>
      <Container
        sx={{ marginTop: '16px', marginBottom: '16px' }}
        className='root-container'
        maxWidth={containerMaxWidth}
      >
        {children}
      </Container>
    </LocalizationProviderWrapper>
  );
}

import { Routes, Route, HashRouter } from 'react-router-dom';
import Header from '../components/Header';
import CalendarPage from '../pages/CalendarPage';
import SpeedFlowchartPage from '../pages/SpeedFlowchartPage';
import ContextWrapper from './ContextWrapper';
import PageTemplate from './PageTemplate';

/**
 *
 * @return {JSX.Element}
 */
export default function Routing() {
  return (
    <HashRouter>
      <ContextWrapper>
        <>
          <Header />
          <PageTemplate>
            <Routes>
              <Route path='/' element={<CalendarPage />} />
              <Route
                path='/speed-flowchart/:competitionId/:categoryId'
                element={<SpeedFlowchartPage />}
              ></Route>
            </Routes>
          </PageTemplate>
        </>
      </ContextWrapper>
    </HashRouter>
  );
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../data/Context';
import { Competiton } from '../models/Competition';

/**
 *
 * @return {JSX.Element} calendar page
 */
export default function CalendarPage() {
  const competitions = React.useRef<Competiton[]>([]);
  const [filteredCompetitions, setFilteredCompetitions] =
    React.useState<Competiton[]>();
  const [filter, setFilter] = React.useState<string>();

  const { api, setTitle, setContainerMaxWidth } = React.useContext(Context);
  const navigate = useNavigate();

  React.useEffect(() => {
    setTitle('DAV calendar');
    setContainerMaxWidth('lg');
  }, []);

  React.useEffect(() => {
    api.getCompetitions('GER').then(result => {
      competitions.current = result.competitions;
      setFilteredCompetitions(
        insertTodayIntoCompetitions(filterCompetitions(competitions.current)),
      );
    });
  }, []);

  React.useEffect(() => {
    if (competitions.current.length > 0)
      setFilteredCompetitions(
        insertTodayIntoCompetitions(filterCompetitions(competitions.current)),
      );
  }, [filter]);

  const filterCompetitions = (competitions: Competiton[]) => {
    return competitions.filter(c => {
      const queryMatches = c.name
        .toLowerCase()
        .includes(filter?.toLocaleLowerCase() ?? '');

      const disciplineMatches = c.discipline?.includes('speed');

      return queryMatches && disciplineMatches;
    });
  };

  const insertTodayIntoCompetitions = (competitions: Competiton[]) => {
    const today = new Date();
    const todayString = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;

    const todayCompetition: Competiton = {
      name: 'Today',
      date: todayString,
      discipline: 'speed',
      WetId: 'today',
      rkey: '',
      comp_name: '',
      date_span: '',
      cats: [],
      categorys: [],
    };

    const index = competitions.findIndex(c => {
      const compDate = new Date(c.date);

      return (
        compDate.getMonth() >= today.getMonth() &&
        compDate.getDate() >= today.getDate()
      );
    });

    if (index === -1) competitions.push(todayCompetition);
    else competitions.splice(index, 0, todayCompetition);

    console.log(competitions);
    console.log(index);

    return competitions;
  };

  const openCompetition = (competitionId: string, categoryId: string) => {
    navigate(`/speed-flowchart/${competitionId}/${categoryId}`);
  };

  return (
    <>
      <Grid container spacing={2} direction={'column'}>
        <Grid item>
          <TextField
            label='search for competition'
            variant='outlined'
            onChange={e => setFilter(e.target.value)}
            fullWidth
          />
        </Grid>
        {filteredCompetitions?.map(competition =>
          competition.WetId === 'today' ? (
            <Grid item xs={12}>
              <Divider>
                <Chip label='Today' />
              </Divider>
            </Grid>
          ) : (
            <Grid item key={`competition-card-${competition.WetId}`}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    {competition.name}
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {competition.date_span}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container spacing={2}>
                    {competition.cats.map(category => (
                      <Grid
                        item
                        key={`competition-card-${competition.WetId}-category-button-${category.GrpId}`}
                        xs
                      >
                        <Button
                          size='small'
                          fullWidth
                          sx={{ width: '100%' }}
                          onClick={() =>
                            openCompetition(competition.WetId, category.GrpId)
                          }
                        >
                          {category.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ),
        )}
        {filteredCompetitions === undefined && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Skeleton height={50}></Skeleton>
                <Skeleton></Skeleton>
              </CardContent>
              <CardActions>
                <Skeleton width={200}></Skeleton>
              </CardActions>
            </Card>
          </Grid>
        )}
        {filteredCompetitions?.length === 0 && (
          <Grid item xs={12} className={'center-children'}>
            Nothing found!
          </Grid>
        )}
      </Grid>
    </>
  );
}

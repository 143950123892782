import { Breakpoint } from '@mui/material/styles';
import React from 'react';
import { Context } from '../data/Context';
import { DigitalrockAPi } from '../data/DigitalrockApi';

/**
 * Sets Context for its children
 * @param  {{ children: JSX.Element }} props
 * @return {JSX.Element}
 */
export default function ContextWrapper(props: { children: JSX.Element }) {
  const { children } = props;

  const api = new DigitalrockAPi();
  const [title, setTitle] = React.useState('');
  const [containerMaxWidth, setContainerMaxWidth] = React.useState<
    false | Breakpoint | undefined
  >('lg');

  return (
    <Context.Provider
      value={{ api, title, setTitle, containerMaxWidth, setContainerMaxWidth }}
    >
      {children}
    </Context.Provider>
  );
}

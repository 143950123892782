import { Context } from '../data/Context';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  convertResultsToSpeedFlowchartResult,
  SpeedFlowchartResult,
  SpeedLane,
  SpeedRoundPair,
} from '../data/SpeedFlowchart';
import {
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { theme } from '../utils/Theme';
import { QRCode } from 'react-qrcode-logo';

import '../css/SpeedFlowchartPage.css';

interface SpeedRoundPairDummy extends SpeedRoundPair {
  dummy: boolean;
}

/**
 *
 * @return {JSX.Element} speed flowchart page
 */
export default function SpeedFlowchartPage() {
  const { competitionId, categoryId } = useParams();
  const { api, setTitle, setContainerMaxWidth } = useContext(Context);

  const [loading, setLoading] = useState(true);
  const [flowchartResult, setFlowchartResult] =
    useState<SpeedFlowchartResult>();

  useEffect(() => {
    setTitle('');
    setContainerMaxWidth(false);

    loadData();

    const interval = setInterval(loadData, 2000);

    return () => clearInterval(interval);
  }, []);

  const loadData = () => {
    if (competitionId === undefined || categoryId === undefined) {
      setLoading(false);
      return;
    }

    api.getCompetitionResults(competitionId, categoryId).then(r => {
      setTitle(
        `${r.comp_name} - ${
          r.categorys.filter(cat => cat.GrpId === categoryId)[0]?.name ?? ''
        }`,
      );

      setLoading(false);

      const flowchartResult = convertResultsToSpeedFlowchartResult(r);
      const l = flowchartResult.rounds.length;
      const dummy: SpeedRoundPairDummy = { dummy: true };
      flowchartResult.rounds[l - 2].pairs = [
        dummy,
        ...flowchartResult.rounds[l - 1].pairs,
        ...flowchartResult.rounds[l - 2].pairs,
      ];
      flowchartResult.rounds[l - 2].roundName = `${
        flowchartResult.rounds[l - 1].roundName
      } / ${flowchartResult.rounds[l - 2].roundName}`;
      flowchartResult.rounds.pop();
      setFlowchartResult(flowchartResult);
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction={{ xs: 'column-reverse', md: 'row' }}
        sx={{
          height: { md: 'calc(100vh - 64px - 16px - 16px)' },
        }}
      >
        {flowchartResult?.rounds.map((round, roundKey) => (
          <Grid
            key={`flowchart-column-${roundKey}`}
            item
            xs={12}
            md={12 / flowchartResult.rounds.length}
          >
            <Grid item className='center-children' xs={12}>
              <Typography variant='h6' fontWeight={'bold'} gutterBottom>
                {round.roundName}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                height: '100%',
                justifyContent: 'space-around',
                position: 'relative',
              }}
              direction={'column'}
            >
              {round.pairs.length === 2 && (
                <Container
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                  }}
                >
                  <QRCode
                    value={`https://bluerock.itsblue.de/?comp=${competitionId}&cat=${categoryId}`}
                    logoImage={'/logo192.png'}
                    logoWidth={100}
                    logoHeight={100}
                    size={250}
                    ecLevel={'H'}
                  />
                </Container>
              )}
              {round.pairs.map((pair, pairKey) => (
                <Grid key={`flowchart-column-${roundKey}-pair-${pairKey}`} item>
                  <Card
                    sx={{
                      opacity: (pair as SpeedRoundPairDummy).dummy ? 0 : 1,
                      [theme.breakpoints.down('md')]: (
                        pair as SpeedRoundPairDummy
                      ).dummy
                        ? {
                            display: 'none',
                          }
                        : undefined,
                    }}
                  >
                    <CardContent
                      sx={{
                        padding: '10px !important',
                      }}
                    >
                      <Grid container spacing={2} alignItems='center'>
                        {[
                          ['A', pair.laneA],
                          ['B', pair.laneB],
                        ].map(([letter, lane]) => {
                          lane = lane as SpeedLane;
                          const textStyle = {
                            fontWeight:
                              pair.winner === letter ? 'bold' : 'plain',
                            color: pair.winner === letter ? '#4CAF50' : '',
                            fontSize: '25px',
                          };

                          const gridItemStyle = {
                            paddingTop:
                              letter === 'B' ? '0 !important' : undefined,
                          };
                          return (
                            <>
                              <Grid sx={gridItemStyle} xs={0.6} item>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: '17px',
                                    color: theme.palette.text.secondary,
                                  }}
                                >
                                  {lane?.participant.results[0].rank}
                                </Typography>
                              </Grid>

                              <Grid
                                sx={{ ...gridItemStyle, display: 'flex' }}
                                xs={8.5}
                                alignItems='center'
                                item
                              >
                                <Typography
                                  sx={{
                                    ...textStyle,
                                    overflow: 'clip',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '80%',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {lane?.participant.firstName}{' '}
                                  {lane?.participant.lastName}
                                </Typography>
                                <Chip
                                  label={lane?.participant.startNumber}
                                  sx={{
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '17px',
                                  }}
                                  variant='outlined'
                                />
                              </Grid>

                              <Grid
                                sx={{
                                  ...gridItemStyle,
                                  display: 'flex',
                                  justifyContent: 'right',
                                }}
                                xs={2.5}
                                item
                              >
                                <Typography sx={textStyle}>
                                  {' '}
                                  {lane?.result?.result}
                                </Typography>
                              </Grid>

                              {letter === 'A' && (
                                <Grid
                                  xs={12}
                                  item
                                  sx={{ height: 0, paddingTop: '0 !important' }}
                                ></Grid>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}

        {flowchartResult === undefined && !loading && (
          <Grid item xs={12} className={'center-children'}>
            This competition or category does not have a speed tree!
          </Grid>
        )}

        {loading &&
          new Array(5).fill(0).map((_, i) => (
            <Grid key={`flowchart-column-skeleton-${i}`} item xs={12 / 5}>
              <Skeleton height={50}></Skeleton>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

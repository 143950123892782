/**
 * A pariticpant of a competition
 */
export interface ParticipantFromApi {
  PerId: string;
  firstname: string;
  lastname: string;
  start_number: string;
  result_rank?: number;
  ['result_rank0']?: string;
  ['result_rank1']?: string;
  ['result_rank2']?: string;
  ['result_rank3']?: string;
  ['result_rank4']?: string;
  ['result_rank5']?: string;
  ['result_rank6']?: string;
  [key: string]: string | number | boolean | undefined;
}
export interface Participant {
  id: string;
  firstName: string;
  lastName: string;
  results: Result[];
  overallRank?: number;
  startNumber: number;
}

export interface Result {
  rank: number;
  result: string;
}

/**
 * Function to extract results from PartiipantFromApi
 * @param {ParticipantFromApi} fromApi
 * @return {Result[]}
 */
function _extractResults(fromApi: ParticipantFromApi): Result[] {
  const results: Result[] = Array(7);

  const existingResults = Object.keys(fromApi).filter(key =>
    key.match(/result[0-9]/),
  );

  for (const result of existingResults) {
    let roundNumber = 0;
    const match = result.match(/result([0-9])/);
    if (match !== undefined && match !== null) {
      roundNumber = parseInt(match[1]);
    }
    if (roundNumber < 0) {
      continue;
    }
    results[roundNumber] = {
      rank: parseInt(fromApi[`result_rank${roundNumber}`] as string),
      result: fromApi[`result${roundNumber}`] as string,
    };
  }
  return results;
}

/**
 * Function to clean up participants from the api
 * @param {ParticipantFromApi} fromApi
 * @return {Participant}
 */
export function participantFromApiParticipant(
  fromApi: ParticipantFromApi,
): Participant {
  const results = _extractResults(fromApi);

  return {
    id: fromApi.PerId,
    firstName: fromApi.firstname,
    lastName: fromApi.lastname,
    results: results,
    overallRank: fromApi.result_rank,
    startNumber: parseInt(fromApi.start_number),
  };
}
